import { Crypto } from 'ontology-ts-sdk';
import { reverseHex } from '../convert';

function addressToHash(address) {
  return new Crypto.Address(address).toHexString();
}

function addressToHex(address) {
  return reverseHex(addressToHash(address));
}

function isValidAddress(address) {
  try {
    const hex = addressToHex(address);
    return new Crypto.Address(hex).toBase58() === address;
  } catch {
    return false;
  }
}

export default {
  install() {},
  addressToHash,
  addressToHex,
  isValidAddress,
};
